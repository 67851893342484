<template>
  <div class="pt-5">
    <CommonExpansionPanel :currentPanel="currentPanel" :readonly="readonly">
      <template v-slot:title-panel>
        Datos Generales de la solicitud
      </template>
      <v-expansion-panel-content>
        <v-form ref="firstStepForm" :lazy-validation="true">
          <v-row>
            <v-col cols="12" md="6" lg="3" xl="3" v-if="!isAgente">
              <v-autocomplete
                label="Origen de la solicitud"
                :items="itemsOrigenSolicitud"
                v-model="solicitudData.origenSolicitudSelected"
                placeholder="Requerido"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="3" xl="3" v-if="!isAgente">
              <v-autocomplete
                label="Agente"
                :items="agentesConRamos"
                item-text="nombre"
                item-value="id"
                v-model="solicitudData.agente"
                placeholder="Requerido"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="3" xl="3" v-if="isAgenteDirecto">
              <v-autocomplete
                label="Comercial Asignado"
                :items="comercialAsignadoList"
                item-text="nombre"
                item-value="id"
                v-model="solicitudData.comercial_asignado"
                :readonly="isAgente == true"
                placeholder="Requerido"
                :rules="requiredRule"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="3" xl="3">
              <v-autocomplete
                label="Tipo de solicitud"
                :items="tiposSolicitud"
                v-model="solicitudData.tipoSelected"
                placeholder="Requerido"
                :rules="requiredRule"
                outlined
                :no-data-text="'No se encontraron resultados...'"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="3"
              xl="3"
              v-if="esInbound && solicitudData.tipoSelected != null"
            >
              <v-autocomplete
                label="Actividad de solicitud"
                :items="actidadesSolicitud"
                v-model="solicitudData.actividadSolicitud"
                placeholder="Requerido"
                :rules="requiredRule"
                :no-data-text="'No se encontraron resultados...'"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col v-if="!esInbound" m="12" md="6" lg="3" xl="3">
              <v-autocomplete
                label="Ramo"
                :items="currentRamos"
                v-model="solicitudData.categoriaSelected"
                placeholder="Requerido"
                item-text="etiqueta"
                item-value="ramo"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="!esInbound" m="12" md="6" lg="3" xl="3">
              <v-autocomplete
                label="Productos"
                v-model="solicitudData.producto_id"
                :items="currentproducto_id"
                item-text="nombre"
                item-value="id"
                placeholder="Requerido"
                :rules="requiredRule"
                outlined
                color="#00a7e4"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" xs="12" v-if="esInbound">
              <v-file-input
                v-model="solicitudData.archivos"
                color="deep-purple accent-4"
                counter
                multiple
                placeholder="Archivo INE"
                prepend-icon="mdi-paperclip"
                outlined
                :show-size="1000"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    v-if="index < 2"
                    color="deep-purple accent-4"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>

                  <span
                    v-else-if="index === 2"
                    class="text-overline grey--text text--darken-3 mx-2"
                  >
                    +{{ ineFile.length - 2 }} Archivos(s)
                  </span>
                </template>
              </v-file-input>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3" v-if="false">
              <v-autocomplete
                label="Campaña"
                v-model="solicitudData.campania_id"
                :items="esInbound ? campaniasInbound : currentCampanias"
                item-text="producto"
                item-value="id"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
                readonly
              ></v-autocomplete>
            </v-col>
            <v-col m="12" md="6" lg="6" xl="3" v-if="false">
              <v-text-field
                v-model="solicitudData.idOrigenLead"
                label="ID Origen Lead"
                :rules="requiredRule"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="12">
              <v-textarea
                label="Detalle Solicitud"
                v-model="solicitudData.detalle"
                rows="2"
                auto-grow
                :rules="requiredRule"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="text-align: end;"
            >
              <v-btn rounded dark class="common-botton" @click="validateStep">
                <span style="padding-right: 11%;">
                  Siguiente
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import { mainAxios } from "@/mainAxios.js";
import moment from "moment";

export default {
  components: {
    CommonExpansionPanel,
  },
  props: {
    currentStep: Number,
  },
  data() {
    return {
      solicitudData: {
        origenSolicitudSelected: null,
        agente: null,
        categoriaSelected: null,
        comercial_asignado: null,
        tipoSelected: null,
        actividadSolicitud: null,
        producto_id: null,
        detalle: null,
        archivos: null,
        campania_id: null,
        idOrigenLead: 1,
        alianzaId:null
      },
      tipo_polizaPolizaList: ["Nueva", "Renovación"],
      requiredRule: [(v) => !!v || "El campo es requerido"],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      campanias: [],
      campaniasInbound: [],
      agentes: [],
      ramos: [],
      producto_id: [],
      productoOrigal: [],
      tiposSolicitud: [],
      tiposSolicitudCompletos: [],
      pago_idList: [
        { id: 1, name: "Anual" },
        { id: 2, name: "Semestral" },
        { id: 3, name: "Trimestral" },
        { id: 4, name: "Mensual" },
      ],
      forma_pagoList: [
        { id: 1, name: "Tarjeta de Crédito" },
        { id: 2, name: "Tarjeta de Débito" },
        { id: 3, name: "Depósito en banco" },
        { id: 4, name: "Transferencia" },
        { id: 5, name: "Cheque" },
        { id: 6, name: "Efectivo" },
        { id: 7, name: "Domiciliación" },
        { id: 8, name: "Cargo único" },
      ],
      currentPanel: 0,
      readonly: true,
      ramosIdsUsuario:[]
    };
  },
  watch: {
    "solicitudData.agente"() {
      this.solicitudData.campania_id = null;
      this.solicitudData.categoriaSelected = null;
      this.solicitudData.producto_id = null;
      this.solicitudData.alianzaId = null;
      this.getCurrentAlianzaId();
      this.getCurrentCampaña();
      if(this.isAgente)
        this.getCurrentProducto();

    },

    "solicitudData.categoriaSelected"() {
      this.solicitudData.campania_id = null;
      this.solicitudData.producto_id = null;
      this.getCurrentAlianzaId();
      this.getCurrentCampaña();
      if(this.isAgente)
        this.getCurrentProducto();

    },

    "solicitudData.inicio"(val) {
      var date = moment(val).format("YYYY-MM-DD");
      if (date != "Invalid date")
        this.solicitudData.fin = moment(date)
          .add(1, "year")
          .format("YYYY-MM-DD");
    },

    "solicitudData.pago_id"() {
      this.calculateCuotas();
    },

    "solicitudData.anios"(val) {
      this.calculateCuotas();
      if (val == 1) this.solicitudData.subsecuente_pago = null;
    },

    currentStep(val) {
      console.log({ valData: val });
      if (val == 1) {
        this.currentPanel = 0;
        this.readonly = false;
      } else {
        this.currentPanel = 1;
        this.readonly = true;
      }
    },

    "solicitudData.producto_id"(id) {
      let producto = this.producto_id.find((element) => element.id === id);
      this.solicitudData.aseguradora_id = producto
        ? producto.aseguradoraId
        : null;
    },

    "solicitudData.campania_id"(id) {
      let campania = this.campanias.find((element) => element.id === id);
      this.solicitudData.campania = campania ? campania.producto : null;
    },

    "solicitudData.campania"(val) {
      if (this.esInbound) {
        var currentCampania = this.campaniasInbound.find(
          (e) => e.campania == val
        );
        this.form.agente = currentCampania.agenteId;
        this.form.productoSelected = currentCampania.productoId;
        this.form.idOrigenLead = 1;
        this.form.categoriaSelected = currentCampania.ramo;
      }
    },

    "solicitudData.tipoSelected"(val) {
      if (this.esInbound) {
        this.actidadesSolicitud = [];
        var result = this.tiposSolicitudCompletos.filter(
          (e) => e.tipo == val && e.inbound == 1
        );
        result.map((e) => {
          this.actidadesSolicitud.push(e.actividad);
        });
        this.actidadesSolicitud.sort();
        this.solicitudData.actividadSolicitud = null;
      }
    },
  },
  computed: {
    currentRamos() {
      if (this.solicitudData.agente != null)
        return this.agentes.find((e) => e.id == this.solicitudData.agente)
          .ramosList;
      else return [];
    },
    currentproducto_id() {
      if (this.solicitudData.categoriaSelected != null)
        return this.producto_id.filter(
          (e) => e.ramo == this.solicitudData.categoriaSelected
        );
      else return [];
    },
    currentCampanias() {
      if (this.solicitudData.categoriaSelected != null)
        return this.campanias.filter(
          (e) => e.ramo == this.solicitudData.categoriaSelected
        );
      else return [];
    },
    isAgente() {
      return this.rol == "AGENT";
    },
    isAgenteDirecto() {
      const agente = this.agentes.find(
        (a) => a.id == this.solicitudData.agente
      );
      if (!agente) return false;

      return !!agente.canalList.find((canal) => canal.id == 3);
    },
    esInbound() {
      return this.rol.includes("INBOUND");
    },
    itemsOrigenSolicitud() {
      if (this.esInbound) {
        return [
          "Correo electrónico",
          "Llamada telefónica entrada",
          "Llamada telefónica Salida",
          "Whatsapp",
          "Redes sociales",
        ];
      } else {
        return [
          "Correo electrónico",
          "Llamada telefónica",
          "Presencial",
          "Whatsapp",
          "Portal",
          "Otro",
        ];
      }
    },
    agentesConRamos() {
      return this.agentes.filter(agente => this.tieneRamos(agente));
    },
  },
  methods: {
    getCampanias() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/campania/lead-segurify/list", config)
        .then((response) => {
          this.campanias = response.data;
        });
    },

    getAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList,
            ramosList: element.ramosList ? element.ramosList : [],
            alianzaId: element.alianzaId ? element.alianzaId : null
          });
        });
      });
    },

    obtenerUsersCanalDirecto() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/solicitud/agente/directo/list", config)
        .then((response) => {
          this.comercialAsignadoList = response.data;
        });
    },

    getRamos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/ramo/list`, config).then((response) => {
        this.ramos = response.data;
      });
    },

    getproducto_id() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/producto/list", config).then((response) => {
        response.data.map((element) => {
          this.producto_id.push({
            nombre:
              `${element.ramo}` +
              " / " +
              `${element.nombre}` +
              " / " +
              `${element.nombreAseguradora}`,
            id: element.id,
            canal: element.canal ? element.canal : "Todos",
            ramo: element.ramo ? element.ramo : "No disponible",
            aseguradoraId: element.aseguradoraId
              ? element.aseguradoraId
              : "No disponible",
            tecnologia: element.tecnologia ? element.tecnologia : false,
          });
        });
        this.productoOrigal = this.producto_id;
      });
    },

    getTipoSolitud() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/tipoSolicitud/list", config).then((response) => {
        console.log({ response: response.data });
        this.tiposSolicitudCompletos = [];
        this.tiposSolicitud = [];
        if (this.rol.includes("INBOUND"))
          this.tiposSolicitudCompletos = response.data.filter(
            (e) => e.inbound == "1"
          );
        else
          this.tiposSolicitudCompletos = response.data.filter(
            (e) => e.inbound == "0"
          );

        this.tiposSolicitudCompletos.map((e) => {
          this.tiposSolicitud.push(e.tipo);
        });
        this.tiposSolicitud = this.tiposSolicitud.filter(function(
          valor,
          indice,
          arreglo
        ) {
          return arreglo.indexOf(valor) === indice;
        });
        this.tiposSolicitud.sort();
      });
    },

    getCampaniasInbound() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/campaniasInboundEci/list", config).then((response) => {
        this.campaniasInbound = response.data;
        this.campaniasInbound.sort(function(a, b) {
          var campaniaA = a.campania.toUpperCase();
          var campaniaB = b.campania.toUpperCase();
          if (campaniaA < campaniaB) {
            return -1;
          }
          if (campaniaA > campaniaB) {
            return 1;
          }
          return 0;
        });
      });
    },

    calculateDates() {
      this.solicitudData.inicio = moment().format("YYYY-MM-DD");
      this.solicitudData.fechaPrimerPago = moment().format("YYYY-MM-DD");
      this.solicitudData.fin = moment()
        .add(1, "year")
        .format("YYYY-MM-DD");
    },

    calculateCuotas() {
      switch (this.solicitudData.pago_id) {
        case 1:
          this.solicitudData.numeroCuotas = 1 * this.solicitudData.anios;
          break;
        case 2:
          this.solicitudData.numeroCuotas = 2 * this.solicitudData.anios;
          break;
        case 3:
          this.solicitudData.numeroCuotas = 4 * this.solicitudData.anios;
          break;
        case 4:
          this.solicitudData.numeroCuotas = 12 * this.solicitudData.anios;
          break;
        default:
          break;
      }
    },

    validateStep() {
      if (this.$refs.firstStepForm.validate()) {
        this.$emit("ChangeOnlyStep", 2, this.solicitudData);
        window.dataLayer.push({
          event: "ITFY_solicitud_generales",
          tipo_seguro: this.solicitudData.categoriaSelected,//ramo
          option: this.solicitudData.tipoSelected, //opcion
          asesores: localStorage.getItem("agenteMail"),
        })
      }
    },
    getCurrentCampaña() {
      if (this.solicitudData.agente != null && this.solicitudData.categoriaSelected) {
        var currentAgente = this.agentes.find(
          (e) => e.id == this.solicitudData.agente
        );
        var currentRamo = this.currentRamos.find(
          (e) => e.ramo == this.solicitudData.categoriaSelected
        );
        var currentCanalList = currentAgente.canalList;
        var currentCampania = {}
        if(currentCanalList[0] == 'MASIVOS'){
          currentCampania = this.campanias.find((e) => {
            return (
              e.canal == currentCanalList[0].canal && e.ramo == currentRamo.ramo && e.agente_id == null
            );
          });
        }else{
          currentCampania = this.campanias.find((e) => {
            return (
              e.canal == currentCanalList[0].canal && e.ramo == currentRamo.ramo
            );
          });
        }
        
        this.solicitudData.campania_id = currentCampania ? currentCampania.id : "";
        console.log(currentAgente)
        this.solicitudData.alianzaId = currentAgente ? currentAgente.alianzaId : null

      }
    },
    getCurrentProducto(){
      if (this.solicitudData.agente != null && this.solicitudData.categoriaSelected) {
        var currentAgente = this.agentes.find(
          (e) => e.id == this.solicitudData.agente
        );
        var currentRamo = this.currentRamos.find(
          (e) => e.ramo == this.solicitudData.categoriaSelected
        );
        var currentCanalList = currentAgente.canalList;
        var currentCampania = this.currentproducto_id.find((e) => {
          return (
           (e.canal == currentCanalList[0].canal || e.canal == 'Todos') && e.ramo == currentRamo.ramo)
        });
        console.log(currentAgente)

        this.solicitudData.producto_id = currentCampania ? currentCampania.id : "";
        this.solicitudData.alianzaId = currentAgente ? currentAgente.alianzaId : null
      }
    },
    getCurrentAlianzaId(){
      var currentAgente = this.agentes.find(
          (e) => e.id == this.solicitudData.agente
        );
      this.solicitudData.alianzaId = currentAgente ? currentAgente.alianzaId : null
    },
    getUsuarios() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/agente/uuid/${localStorage.agenteUuid}`,config).then((response)=>{
       this.ramosIdsUsuario = response.data.ramos
      });
    },
    tieneRamos(agente) {
      const ramosAgente = agente.ramosList.map(ramo => ramo.id);
      return this.ramosIdsUsuario.some(id => ramosAgente.includes(id));
    },
  },
  mounted() {
    window.dataLayer.push({
      event: "ITFY_solicitud_generales_v",
      no_cotizacion: undefined,
      userID: localStorage.getItem("agenteMail"),
      correo: undefined,
      telefono: undefined,
    })
    this.getCampanias();
    this.getAgentes();
    this.getRamos();
    this.getproducto_id();
    this.calculateDates();
    this.obtenerUsersCanalDirecto();
    this.getTipoSolitud();
    this.getUsuarios()
    if (this.rol == "AGENT") this.solicitudData.agente = parseInt(localStorage.id);
  },
};
</script>
