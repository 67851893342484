<template>
  <div class="pt-5">
    <CommonExpansionPanel :currentPanel="currentPanel" :readonly="readonly">
      <template v-slot:title-panel>
        Datos del cliente
      </template>
      <v-expansion-panel-content>
        <v-form ref="firstStepForm" :lazy-validation="true">
          <v-row>
            <v-col m="12" md="6" lg="4" xl="4">
              <v-autocomplete
                label="Tipo de Persona (Régimen)"
                outlined
                v-model="polizaData.regimen_persona"
                color="#00a7e4"
                type="text"
                :items="regimentList"
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="4"
              xl="4"
              v-if="polizaData.regimen_persona == 'Moral'"
            >
              <v-text-field
                label="Razón Social"
                outlined
                v-model="polizaData.razon_social"
                color="#00a7e4"
                type="text"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="polizaData.regimen_persona != null">
            <v-col m="12" md="6" lg="3" xl="3">
              <v-text-field
                v-model="polizaData.nombres"
                label="Nombre(s)"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRuleCaracteres"
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-text-field
                v-model="polizaData.apellidoPaterno"
                label="Apellido Paterno"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRuleCaracteres"
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-text-field
                v-model="polizaData.apellidoMaterno"
                label="Apellido Materno"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRuleCaracteres"
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-text-field
                v-model="polizaData.fechaNacimiento"
                label="Fecha de Nacimiento"
                outlined
                type="date"
                color="#00a7e4"
                :rules="requiredRuleDate"
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-text-field
                v-model="polizaData.edad"
                type="text"
                label="Edad"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.regimen_persona != null"
            >
              <v-text-field
                v-model="polizaData.rfc"
                label="RFC"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="rfcRules"
                @input="onInputToUpper"
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-autocomplete
                label="Género"
                outlined
                color="#00a7e4"
                v-model="polizaData.genero"
                :items="generoList"
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-autocomplete
                label="Estado civil"
                outlined
                color="#00a7e4"
                v-model="polizaData.estadoCivilSelected"
                :items="['Soltero(a)', 'Casado(a)']"
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-text-field
                label="Teléfono"
                outlined
                color="#00a7e4"
                v-mask="'##########'"
                v-model="polizaData.celular"
                :rules="[requiredRule, celularRule]"
                ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <CommonEmailAutocomplete
              @emailUpdate="(e) => (polizaData.correoElectronico = e)"
            ></CommonEmailAutocomplete>
            </v-col>
          </v-row>
          <v-row v-if="polizaData.regimen_persona != null">
            <v-col m="12" md="6" lg="3" xl="3">
              <v-text-field
                v-model="polizaData.codigoPostal"
                label="Código Postal"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.codigoPostal != null"
            >
              <v-text-field
                v-model="polizaData.calle"
                label="Calle"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.codigoPostal != null"
            >
              <v-text-field
                v-model="polizaData.numExterior"
                label="Numero Exterior"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.codigoPostal != null"
            >
              <v-text-field
                v-model="polizaData.numInterior"
                label="Numero interior"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.codigoPostal != null"
            >
              <v-autocomplete
                label="Colonia"
                v-model="polizaData.colonia"
                :items="colonias"
                item-text="nombreColonia"
                item-value="nombreColonia"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.codigoPostal != null"
            >
              <v-text-field
                label="Ciudad"
                v-model="polizaData.municipio"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRule"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.codigoPostal != null"
            >
              <v-autocomplete
                label="Estado de la República"
                v-model="polizaData.estadoRepublicaSelected"
                placeholder="Requerido"
                :items="estados"
                item-text="name"
                item-value="name"
                color="#00a7e4"
                type="text"
                readonly
                outlined
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="text-align: end;"
            >
              <v-btn
                rounded
                dark
                outlined
                color="#00a7e4"
                @click="beforeStep"
                style="margin-right: 11px;"
              >
                <span>
                  Anterior
                </span>
              </v-btn>
              <v-btn rounded dark class="common-botton" @click="validateStep">
                <span>
                  Siguiente
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import { mainAxios } from "@/mainAxios.js";
import moment from "moment";
import RfcFacil from "rfc-facil";
import CommonEmailAutocomplete from "@/components/commonComponents/CommonEmailAutocomplete.vue";

export default {
  components: {
    CommonExpansionPanel,
    CommonEmailAutocomplete
  },
  props: {
    currentStep: Number,
  },
  data() {
    return {
      polizaData: {
        regimen_persona: 'Física',
        razon_social: null,
        rfc: null,
        nombre: null,
        fecha_nacimiento: null,
        edad: null,
      },
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      regimentList: ["Física", "Moral"],
      rfcRules: [
        (v) => (v.length === 10 || v.length === 12 || v.length === 13) || "RFC debe tener 10, 12 o 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^[A-Za-z\d]{10,13}$/.test(v) ||
          "RFC no es válido, asegúrate de tener la longitud correcta y utiliza caracteres alfanuméricos",
      ],
      requiredRule: [(v) => !!v || "El campo es requerido"],
      generoList: ["Masculino", "Femenino"],
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail debe de ser valido",
      ],
      colonias: [],
      estados: [
        { id: 1, name: "Aguascalientes" },
        { id: 2, name: "Baja California" },
        { id: 3, name: "Baja California Sur" },
        { id: 4, name: "Campeche" },
        { id: 5, name: "Coahuila de Zaragoza" },
        { id: 6, name: "Colima" },
        { id: 7, name: "Chiapas" },
        { id: 8, name: "Chihuahua" },
        { id: 9, name: "Ciudad de México" },
        { id: 10, name: "Durango" },
        { id: 11, name: "Guanajuato" },
        { id: 12, name: "Guerrero" },
        { id: 13, name: "Hidalgo" },
        { id: 14, name: "Jalisco" },
        { id: 15, name: "Estado de México" },
        { id: 16, name: "Michoacán de Ocampo" },
        { id: 17, name: "Morelos" },
        { id: 18, name: "Nayarit" },
        { id: 19, name: "Nuevo León" },
        { id: 20, name: "Oaxaca" },
        { id: 21, name: "Puebla" },
        { id: 22, name: "Querétaro" },
        { id: 23, name: "Quintana Roo" },
        { id: 24, name: "San Luis Potosí" },
        { id: 25, name: "Sinaloa" },
        { id: 26, name: "Sonora" },
        { id: 27, name: "Tabasco" },
        { id: 28, name: "Tamaulipas" },
        { id: 29, name: "Tlaxcala" },
        { id: 30, name: "Veracruz de Ignacio de la Llave" },
        { id: 31, name: "Yucatán" },
        { id: 32, name: "Zacatecas" },
      ],
      currentPanel: null,
      readonly: true,
      requiredRuleCaracteres: [
        v => !!v || 'Este campo es requerido', // Verifica que el campo no esté vacío
        v => (v && v.length >= 3) || 'Mínimo 3 caracteres', // Mínimo de 3 caracteres
        v => /^[a-zA-Z\s]+$/.test(v) || 'No se permiten caracteres especiales' // Solo letras y espacios
      ],
      requiredRuleDate: [
        v => !!v || 'La fecha es requerida.',
        v => this.isPastDate(v) || 'La fecha no puede ser hoy ni una en el futuro.',
        v => this.isWithinLast100Years(v) || 'La fecha no puede ser mayor a 100 años.',
      ],
      celularRule: (value) => {
          // Verifica que el número tenga exactamente 10 dígitos
          const formato = /^\d{10}$/;
          if (!formato.test(value)) {
            return "El teléfono debe tener 10 dígitos";
          }
          // Evita secuencias numéricas ascendentes o descendentes como "1234567890" o "9876543210"
          const secuenciaAscendente = /^(\d)(\d)\d{8}$/;
          if (secuenciaAscendente.test(value)) {
            const [, a, b] = secuenciaAscendente.exec(value);
            if (+b === +a + 1) {
              return "Teléfono no válido (secuencia numérica)";
            }
          }
          // Verifica que no tenga todos los mismos dígitos, como "1111111111" o "0000000000"
          const soloRepetidos = /^(\d)\1{9}$/;
          if (soloRepetidos.test(value)) {
            return "Teléfono no válido (número repetitivo)";
          }

          // Si ninguna de las reglas anteriores se cumple, el número es válido
          return true;
        }
      };
  },

  watch: {
    "polizaData.fechaNacimiento"(val) {
      var today = moment().format("YYYY-MM-DD");
      var birthday = moment(val, "YYYY-MM-DD");
      this.polizaData.edad = moment(today, "YYYY-MM-DD").diff(
        moment(birthday, "YYYY-MM-DD"),
        "years"
      );
      this.actualizarRfc();
    },
    "polizaData.codigoPostal"(val) {
      if (typeof val != "undefined") {
        if (val.length == 5) {
          const config = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          };
          mainAxios
            .get("/v1/catalogo/direccion?cp=" + val, config)
            .then((response) => {
              if (response.data.listaColonias.length >= 1) {
                this.colonias = response.data.listaColonias;
                this.polizaData.estadoRepublicaSelected = this.estados.find(
                  (e) => e.id == response.data.nEstado
                ).name;
                this.polizaData.municipio = response.data.nombreMunicipio;
              } else if (response.error == "") {
                alert("No existe ese codigo postal");
              }
            });
        }
      }
    },
    "polizaData.nombres"() {
      this.actualizarRfc();
    },
    "polizaData.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "polizaData.apellidoMaterno"() {
      this.actualizarRfc();
    },
    currentStep(val) {
      if (val == 2) {
        this.currentPanel = 0;
        this.readonly = false;
      } else {
        this.currentPanel = 1;
        this.readonly = true;
      }
    },
  },
  methods: {
    validateStep() {
      if (this.$refs.firstStepForm.validate()) {
        (this.polizaData.nombre_cliente = `${this.polizaData.nombre} 
        ${this.polizaData.apellido_paterno} 
        ${this.polizaData.apellido_materno}`),
        this.$emit("ChangeOnlyStep", 3, this.polizaData)
        const requestUserData = {
          correo: this.polizaData.correoElectronico,
          telefono: this.polizaData.celular,
        };
        sessionStorage.setItem("userRequestData", JSON.stringify(requestUserData));
        window.dataLayer.push({
        event: "ITFY_solicitud_datos_cliente",
        fecha_nacimiento: this.polizaData.fechaNacimiento.split("-").reverse().join("/"),
        sexo: this.polizaData.genero,
        correo: this.polizaData.correoElectronico,
        userID: localStorage.getItem("agenteMail"),
        telefono: this.polizaData.celular,
        cp: this.polizaData.codigoPostal,
      })
      }
    },
    beforeStep() {
      this.$emit("ChangeOnlyStep", 1, null);
    },
    actualizarRfc() {
      if (
        !this.polizaData.nombres ||
        !this.polizaData.apellidoPaterno ||
        !this.polizaData.apellidoMaterno ||
        !this.polizaData.fechaNacimiento
      )
        return;
      const arregloNacimiento = moment(this.polizaData.fechaNacimiento)
        .format("DD/MM/YYYY")
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.polizaData.nombres,
        firstLastName: this.polizaData.apellidoPaterno,
        secondLastName: this.polizaData.apellidoMaterno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.polizaData.rfc = rfc;
    },
    onInputToUpper() {
      this.polizaData.rfc = this.polizaData.rfc.toUpperCase();
    },
    isPastDate(value) {
      const selectedDate = new Date(value);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Ignorar el tiempo para la comparación
      return selectedDate < today;
    },
    isWithinLast100Years(value) {
      const selectedDate = new Date(value);
      const date100YearsAgo = new Date();
      date100YearsAgo.setFullYear(date100YearsAgo.getFullYear() - 100);
      return selectedDate >= date100YearsAgo;
    },
  },
  mounted() {},
};
</script>
